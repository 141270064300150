import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment'
import router from "@/router";
import { store } from "@/store";
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
let socket = io('https://api.paygaranti.com');
if (process.env.NODE_ENV != 'production') {
   socket = io('https://api.paygaranti.com');
}
Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket);
Vue.filter("currency",(value) => {
  return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
});
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:MM')
  }
})

//styles
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('./assets/css/fontawesome.min.css')
require('./assets/css/main.css');
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
