import axios from 'axios';
let apiUrl = ''
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.paygaranti.com'
} else {
    apiUrl = 'http://localhost:3000'

   }

export default {
    checkSid(data) {
        return axios.post(apiUrl + '/payment/check-sid', data)
            .then(response => response.data)
    },
    siteDetail(data) {
        return axios.post(apiUrl + '/payment/site-detail', data)
            .then(response => response.data)
    },
    checkPayment(data) {
        return axios.post(apiUrl + '/payment/check-payment', data)
            .then(response => response.data)
    },
    sendPaymentByUser(data) {
        return axios.post(apiUrl + '/payment/user-send-payment', data)
            .then(response => response.data)
    },
    checkTrx(data) {
        return axios.post(apiUrl + '/payment/check-trx', data)
            .then(response => response.data)
    },
    getAccount(data){
        return axios.post(apiUrl + '/payment/get-account', data)
            .then(response => response.data)
    },
    banks(){
        return axios.get(apiUrl + '/payment/banks')
            .then(response => response.data)
    }
}